





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class P0922 extends Vue {
  isNoticeOpen = false; //各条件の注意事項の表示フラグ
}
